<template>
  <el-container>
    <el-header>
      <el-input
        class="customInput"
        v-model="filterParams.keyword"
        placeholder="标题关键词检索"
        clearable
      ></el-input>
      <!-- <el-select
        v-model="filterParams.source_from"
        placeholder="来源"
      >
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="filterParams.title"
        placeholder="消息类型"
      >
        <el-option
          v-for="item in options2"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <el-date-picker
        v-model="pickerDate"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00','23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
      <el-button
        type="primary"
        @click="handleFind"
        >查询</el-button
      >
      <el-button @click="reset">重置</el-button>
    </el-header>

    <el-main>
      <el-table
        stripe
        max-height="700"
        :data="tableData"
        style="width: 100%"
        v-loading="tableLoading"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
        ></el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width ? item.width : '100px'"
          v-for="(item, index) in tableColumnData"
          :key="index"
        >
        </el-table-column>
      </el-table>
      <div class="dp-flex justify-end mt-10">
        <el-pagination
          :hide-on-single-page="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="paging.total_count"
          :page-count="paging.total_pages"
          :current-page="filterParams.page_num"
          :page-size="filterParams.page_size"
          @current-change="changePage"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import moment from 'moment';
import { messages } from '@/api/automaticRules';
export default {
  data() {
    return {
      options1: [
        {
          value: '自动化规则',
          label: '自动化规则',
        },
      ],
      options2: [
        {
          value: '仅通知',
          label: '仅通知',
        },
        {
          value: '调整后通知',
          label: '调整后通知',
        },
      ],
      value: '',
      pickerDate: [this.oneMonthAgo, this.today],
      tableData: [],
      tableColumnData: [
        {
          prop: 'title',
          label: '标题',
          width: '500px',
        },
        {
          prop: 'message',
          label: '通知内容',
          width: '650px',
        },
        {
          prop: 'target_type',
          label: '作用对象',
        },
        {
          prop: 'source_from',
          label: '来源',
        },
        {
          prop: 'type',
          label: '消息类型',
        },
        {
          prop: 'time',
          label: '通知时间',
        },
      ],
      filterParams: {
        keyword: '',
        source_from: '',
        title: '',
        start_time: '',
        end_time: '',
        user_id: '',
        page_num: 1,
        page_size: 10,
      },
      paging: {},
      tableLoading: false, //加载表格
    };
  },
  created() {
    this.pickerDate[1] = this.today;
    this.pickerDate[0] = this.oneMonthAgo;
    this.messages();
  },
  computed: {
    today() {
      // 返回格式化的当前日期字符串
      return this.formatDate(new Date(), 'today');
    },
    oneMonthAgo() {
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      // 返回格式化的一个月前的日期字符串
      return this.formatDate(date);
    },
  },
  methods: {
    //改变一页条数
    handleSizeChange(v){
      this.filterParams.page_size = v;
      this.messages();
    },
    //改变页数
    changePage(v) {
      this.filterParams.page_num = v;
      this.messages();
    },
    //获取数据
    messages() {
      this.tableLoading = true;
      let paramsToSend = {};
      paramsToSend.source_from = this.filterParams.source_from;
      paramsToSend.title = this.filterParams.title;
      paramsToSend.keyword = this.filterParams.keyword;
      paramsToSend.start_time = this.pickerDate ? this.pickerDate[0] : '';
      paramsToSend.end_time = this.pickerDate ? this.pickerDate[1] : '';
      paramsToSend.user_id = this.filterParams.user_id;
      paramsToSend.page_number = this.filterParams.page_num;
      paramsToSend.page_size = this.filterParams.page_size;
      messages(paramsToSend).then((res) => {
        this.tableLoading = false;
        if (res.code === 0) {
          this.tableData = res.data&&res.data.contents ? res.data.contents : [];
          this.paging = res.data&&res.data.paging ? res.data.paging : {};
        }
      });
    },
    //查询按钮
    handleFind() {
      this.filterParams.page_num = 1;
      this.filterParams.page_size = 10;
      this.messages();
    },
    //重置按钮
    reset(){
      this.pickerDate = null;
      this.filterParams.keyword = '';
      this.filterParams.source_from = '';
      this.filterParams.title = '';
      this.filterParams.start_time = '';
      this.filterParams.end_time = '';
      this.filterParams.user_id = '';
      this.filterParams.page_num = 1;
      this.filterParams.page_size = 10;
      this.messages();
    },
    //打开添加规则弹窗
    handleOpenDraw() {
      this.$refs.addAutomaticRulesDraw.handleOpen();
    },
    formatDate(date,isToday) {
      var month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds();

      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) day = '0' + day;
      // if (hour < 10) hour = '0' + hour;
      // if (minute < 10) {
      //   minute = `0${minute}`;
      // }
      // if (second < 10) second = '0' + second;
      if (isToday === 'today') {
        return `${[year, month, day].join('-')} ${['23', '59', '59'].join(':')}`;
      } else {
        return `${[year, month, day].join('-')} ${['00', '00', '00'].join(':')}`;
      }
    },
  },
};
</script>

<style lang="scss">
.el-container {
  background: #ffffff;
  .el-header {
    padding: 20px;
    .customInput {
      width: 200px;
    }
    .el-select {
      margin-left: 20px;
    }
    .el-range-editor {
      margin: 0 20px;
    }
  }
}
</style>
